import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "dictionaryItemAdd",
  components: {},
  mixins: [mixins],
  data() {
    return {
      edit: false,
      areas: [],
      roleSections: [],
      formModel: {
        id: null,
        itemLabel: null,
        itemValue: null,
        sortNo: null,
        categoryId: null,
        remark: null
      },
      formRules: {
        itemLabel: [{
          required: true,
          message: '请输入字典标签',
          trigger: 'blur'
        }],
        itemValue: [{
          required: true,
          message: '请输入字典键值',
          trigger: 'blur'
        }],
        sortNo: [{
          required: true,
          message: '请输入路字典顺序',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.dictionary.itemUpdate(this.formModel).then(callBack);
          } else {
            this.$api.business.dictionary.itemCreate(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.dictionary.itemDetail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel = Object.assign(this.formModel, res.data);
        } else if (res.code === 112404) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    } else if (this.$route.query.categoryId) {
      this.formModel.categoryId = this.$route.query.categoryId;
    }
  }
};